<template>
	<div class="pui-form">
		<!-- Tabs Headers -->
		<v-tabs v-model="tab" color="blue" background-color="rgba(0, 0, 0, 0)" class="mt-3" show-arrows>
			<v-tab ref="active-notifications-tab" href="#active-notifications-tab">{{ $t('grid.pmnotifications.active-tab') }}</v-tab>
			<v-tab ref="historic-notifications-tab" href="#historic-notifications-tab">{{ $t('grid.pmnotifications.historic-tab') }}</v-tab>
		</v-tabs>

		<!-- Tabs Content -->
		<v-tabs-items v-model="tab" style="overflow-y: auto; background: rgba(0, 0, 0, 0) !important">
			<v-tab-item value="active-notifications-tab">
				<pui-master-detail
					componentName="PmNotificationsGrid"
					parentModelName=""
					:parentPk="getParentPk()"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item value="historic-notifications-tab">
				<pui-master-detail
					componentName="HistoricalNotificationsGrid"
					parentModelName=""
					:parentPk="getParentPk()"
				></pui-master-detail>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmNotificationsTabs',
	data() {
		return {
			tab: null
		}
	},
	methods: {
		getParentPk() {
			return window.btoa('{}');
		},
	}
};
</script>

<style lang="postcss" scoped></style>
